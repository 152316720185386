.card-box {
  margin: 44px 10px 10px 10px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 240px;
  width: 250px;
  box-shadow: 0px 3px 3px 0px rgba(214, 214, 214, 30%);
  position: relative;
  border-radius: 5px;
  transition: ease-in 0.3s;
  border: 1px solid #e9eaef;
  cursor: pointer;

  &--top {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    .profile {
      overflow: hidden;
      background: white;
      width: 78px;
      height: 78px;
      border-radius: 50%;
      border: 4px solid #e9eaef;
      position: absolute;
      top: -34px;
      left: calc(50% - 36px);
      transition: ease-in 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;
      .heightt{
        height: 100%;
      }
      .widthh{
        width: 100%;
      }
    }
    .dots {
      display: flex;
      align-items: center;
      div {
        border-radius: 50%;
        height: 4px;
        width: 4px;
        background-color: black;
      }
      div + div {
        margin-left: 2px;
      }
    }
    .share {
      padding: 0 5px;
      color: black;
      font-size: 13px;
      border-radius: 3px;
      transition: ease-out 0.2s;
      &:hover {
        background: #e9eaef;
      }
    }
  }
  .info {
    // margin: 34px auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    span{
      text-align: center;
    }
    .name {
      font-weight: 600;
      font-size: 16px;
      color: #3b7ad5;
    }
    .company {
      color: black;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e9eaef;
    &--button {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      font-size: 13px;
      &:nth-of-type(1) {
        border-right: 1px solid #e9eaef;
      }
    }
  }
}
.card-box:hover .card-box--top .profile {
  border: 4px solid #4dbfa9;
}
.card-box:hover {
  border: 1px solid #4dbfa9;
}
.modal-header {
  background: rgb(59, 122, 213);
  color: white;
  height: 64px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-size: 20px;
}
.ant-modal {
  border-radius: 10px;
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close-x i {
    color: white;
  }
}
.menu {
  padding: 10px;
}
.finder {
  padding: 10px 20px;
  display: flex;
  .put-button {
    background: #4dbfa9;
    color: white;
    height: 30px;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 6px;
    cursor: pointer;
  }
}
.userItem {
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
  transition: ease-out 0.2s;
  &:hover {
    background: #e9eaef;
  }
  .avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  &--img {
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  &--placeholder {
    background: #aeb1ba;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--info {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    span:nth-child(1) {
      color: black;
      font-size: 13px;
    }
    span:nth-child(2) {
      // color: black;
      font-size: 12px;
    }
  }
}
.sorts {
  padding: 10px 0;
  div {
    cursor: pointer;
    padding: 5px 10px;
    font-size: 12px;
    &:hover {
      background: #e9eaef;
    }
  }
}
.newContact {
  padding: 10px 20px;
  max-height: 500px;
  overflow-y: auto;
  .two-items {
    display: flex;
    input:nth-child(1) {
      margin-right: 10px;
    }
  }
  .tags-wrapper {
    display: flex;
    flex-wrap: wrap;

    .tag-item {
      background: #e9eaef;
      margin: 3px 5px;
      padding: 2px 10px;
      border-radius: 8px;
      font-size: 13px;
      transition: ease-out 0.2s;
      &:hover {
        background: #3b7ad5;
        color: white;
      }
      span {
        margin-left: 10px;
        cursor: pointer;
        font-weight: 500;
      }
    }
  }
  .form-image {
    display: flex;
    align-items: center;
    .file-uploader {
      margin-left: 20px;
      position: relative;
      cursor: pointer;
      button {
        background: #e99c23;
        color: white;
        font-size: 13px;
        border: none;
      }
      input {
        cursor: pointer;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        margin: 0;
        opacity: 0;
      }
    }
    .placeholder {
      background: #e9eaef;
      height: 78px;
      width: 78px;
      border-radius: 50%;
    }
    .upload-preview {
      height: 78px;
      width: 78px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
  input {
    margin-top: 20px;
  }
  .advanced {
    margin: 10px 0;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    div {
      cursor: pointer;
    }
  }
  .gender {
    display: flex;
    flex-direction: column;
    &--title {
      font-size: 12px;
      color: #acacac;
      padding-left: 6px;
    }
    label {
      margin: 0;
    }
  }
}
.cardbox-menu {
  max-height: 240px;
  overflow-y: auto;
  padding: 6px 0;
  &--item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px 3px;
    transition: ease-out 0.2s;
    color: #424242;
    &:hover {
      background: #e9eaef;
    }
    svg {
      margin-right: 10px;
    }
  }
}

.users-wrap {
  max-height: 300px;
  overflow-y: auto;
}
.capture-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  div {
    min-width: 200px;
    padding: 4px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: ease-out 0.2s;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 500;
    &:hover {
      background: #e9eaef;
    }
  }
}

.shareTypes {
  display: flex;
  flex-direction: column;
  padding: 15px;
  .user {
    color: #3b7ad5;
    font-size: 12px;
    margin-top: 20px;
    padding-left: 30px;
  }
  .notice {
    font-size: 12px;
    padding: 20px 0 0 20px;
  }
  .ant-checkbox-wrapper {
    padding-left: 20px;
  }
  span {
    font-size: 16px;
  }
  div .ant-checkbox-wrapper + div .ant-checkbox-wrapper {
    margin-left: 0;
    margin-top: 5px;
  }
}
.ant-modal-close-x {
  color: white;
}
.tag-reminder {
  color: #aeb1ba;
  font-size: 11px;
}
.campaign-buttons {
  display: flex;
  justify-content: space-between;
  button:nth-child(1) {
    border: none;
    box-shadow: none;
    color: #3b7ad5;
  }
}
.link-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  &--small {
    color: #7e818a;
    font-size: 12px;
  }
  &--url {
    border-bottom: 1px solid #aeb1ba;
    margin-bottom: 10px;
    cursor: text;
  }
  &--button_wrap {
    display: flex;
    justify-content: flex-end;
    button {
      border: 0;
      font-size: 12px;
      box-shadow: none;
      color: #4dbfa9;
      font-weight: 500;
    }
  }
  &--qr {
    margin: auto;
    height: 160px;
    width: 160px;
    background: #3b7ad5;
  }
  &--checks {
    display: flex;
    flex-direction: column;
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin: 0;
      margin-top: 15px;
    }
  }
  .custom_intro {
    margin: 15px 0;
    .input_url {
      font-size: 12px;
    }
  }
  &--socials {
    display: flex;
    flex-direction: column;
    margin: auto;
    &--title {
      color: #3b7ad5;
      font-size: 11px;
      margin-top: 20px;
    }
    a {
      color: white;
      div {
        padding: 5px 20px 5px 10px;
        width: 120px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
      }
      &:hover {
        color: rgb(188, 215, 255);
      }
    }
    a + a div {
      margin-top: 1px;
    }
  }
}
.congrats {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  &--title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 6px;
  }
  &--shared {
    font-size: 12px;
  }
  &--person {
    font-size: 13px;
    font-weight: 600;
  }
  &--type {
    color: #acacac;
    font-size: 12px;
    margin: 12px 0 6px 0;
  }
  &--reminder {
    background: #e99c23;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    border-radius: 3px;
  }
  &--ok {
    background: #4dbfa9;
    color: white;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    box-shadow: 0 4px 4px rgba(214, 214, 214, 30%);
  }
}


.timelines{
  padding: 30px 40px;
  .timeline_item{
    border: 1px solid #f0f0f0;
    margin-left: 20px;
    padding: 15px 25px;
    position: relative;
    &--dots{
      position: absolute;
      top: 8px;
      right: 10px;
      display: flex;
      cursor: pointer;
      transition: ease-out 0.3s;
      padding: 5px;
      border-radius: 15px;
      &:hover{
        background: #e3e3e4;
      }
      div+div{
        margin-left: 2px;
      }
      div{
        background: black;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        
      }
    }
    &--description{
      font-size: 16px;
    }
    &--date{
      font-size: 13px;
      opacity: 0.5;
    }
  }
  .timeline_dot_wrap{
    border-radius: 50%;
    border: 1px solid #f0f0f0;
    padding: 3px;
    .timeline_dot{
      color: white;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg{
        font-size: 16px;
      }
    }
  }
}

.timeline_item{
  &--delete{
    background: white;
    font-size: 14px;
    padding: 5px 0;
    cursor: pointer;
    border: 1px solid #f0f0f0;
    color: #424242;
    div{
      transition: ease-out 0.3s;
      padding: 5px 10px;
      svg{
        margin-right: 10px;
      }
      &:hover{
        background: #f0f0f0;
      }
    }
   
  }
}