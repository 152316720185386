.marketplace-phone {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid transparent;
  position: relative;
  .template_id{
    position: absolute;
    z-index: 8;
    background: #fafafa;
    color: rgba(0,0,0,.65);
    border: 1px solid #d9d9d9;
    top: 0;
    left: 5px;
    padding: 2px 6px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
  }
  &--x_bttn{
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    right: 0;
    top: 0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    z-index: 9;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-out 0.3s;
    font-size: 12px;
  }
  &--image {
    cursor: pointer;
    &:hover{
      box-shadow: 0 0 10px rgb(81, 154, 238);
    }
    border: 2px solid #dedede;
    height: 350px;
    width: 180px;
    border-radius: 10px;
  overflow: hidden;
    background: white;
    img {
      width: 100%;
    }
  }
  &--buttons {
    display: flex;
    justify-content: space-around;
  }
  &--title {
    font-size: 15px;
    margin: 10px 0;
  }
}

// @media (min-width: 769px) {
//   .marketplace-phone {
//     ::-webkit-scrollbar {
//       width: 5px;
//     }

//     /* Track */
//     ::-webkit-scrollbar-track {
//       background: transparent;
//       border-radius: 10px;
//     }

//     /* Handle */
//     ::-webkit-scrollbar-thumb {
//       background: $highlight;
//       border-radius: 10px;
//     }

//     /* Handle on hover */
//     ::-webkit-scrollbar-thumb:hover {
//       background: #0b1d69;
//     }
//   }
// }
.selected{
//  background: rgba(116, 190, 233,0.5);
 border: 2px solid #3568c9;
 animation: bounce 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes bounce{
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.01);
  }
  100%{
    transform: scale(1);
  }
}

.modal_create_new_card{
  &--header{
    background: #3568c9;
    height: 56px;
    color: white;
    font-size: 16px;
    padding: 15px;
  }
  &--form{
    padding: 20px;
    &--profile_switch{
      margin: 20px 0;
      button{
        margin-left: 10px;
      }
    }
    &--field{
      display: flex;

    }
    &--field + &--field{
      margin-top: 20px;
    }
  }
}

@media(min-width: 769px){
  .modal_create_new_card{
    &--header{
      background: #3568c9;
      height: 56px;
      color: white;
      font-size: 16px;
      padding: 15px;
    }
    &--form{
      padding: 20px;
      &--profile_switch{
        padding-left: 30px;
      }
      &--field{
        justify-content: flex-end;
        align-items: center;
        padding-top: 15px;
        input{
          width: 70%;
          margin-left: 20px;
        }
      }
    }
  }
}
@media(max-width: 768px){
  .modal_create_new_card{
    &--form{
      
      &--field{
        flex-direction: column;
        input{
          margin-top: 10px;
        }
      }
    }
  }
}