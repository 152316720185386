$bg : #e2eef0;
$shadow : 0px 4px 4px #e4e4e4;
$icon_color : #74bee9;

.sidebar{
    display: flex;
    height: max-content;
    box-shadow: $shadow;
    position: relative;
    .float-container{
        display: flex;
        transition: ease-out 0.2s;
        position: absolute;
        background: white;
        right: -170px;
        top: 0;
        z-index: 1;
        box-shadow: $shadow;
    }
    .first-bar{
        background: $bg;
        padding: 10px;
        z-index: 3;
        .sidebar-profile{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 60px;
            img{
                height: 100%;
            }
        }
        .icons{
            color: $icon_color;
            font-size: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            div+div{
                margin-top: 4px;
            }
        }
        .question{
            margin-top: 60px;
            display: flex;
            justify-content: center;
            color: $icon_color;
            font-size: 18px;
            margin-bottom: 120px;
        }
        
    }
    .second-bar{
        transition: ease-out 0.5s;
        padding: 10px;
        .main-name{
            margin-top: 8px;
            height: 50px;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 60px;
        }
        .icon-texts{
            margin-top: 4px;
            div{
                text-transform: uppercase;
                font-size: 14px;
                height: 37px;
            }
            div+div{
                margin-top: 4px;
            }
        }
        .utils-text{
            margin-top: 53px;
            margin-bottom: 69px;
            div{
                height: 34px;
                text-transform: uppercase;
            }
        }
    }
    .sidebar-hidden{
        transform: translateX(calc(-100% + 24px));
    }
    .third-bar{
        display: flex;
        justify-content: center;
        align-items: center;
        transition: cubic-bezier(0.215, 0.610, 0.355, 1) 0.5s;
        cursor: pointer;
        color: #ababab;
        padding: 10px 5px;
        &:hover{
            background: #e2eef0;
        }
        svg{
            transition: cubic-bezier(0.215, 0.610, 0.355, 1) 0.5s;
        }
    }
    .chevron-rotate{
        svg{
            transform: rotate(180deg);
        }
    }
}