.no_campaigns{
    text-align: center;
    padding: 60px 0;
}

.campaigns_wrap{
    padding: 20px;
}

.campaign_drop_menu{
    background: white;
    width: max-content;
    box-shadow: 0px 4px 4px #ddd;
    padding: 4px 0;
    div{
        transition: ease-out 0.3s;
        cursor: pointer;
        padding: 3px 8px;
        &:hover{
            background: #eee;
        }
    }
}

.campaign_form_wrap{
    padding: 20px;
    .campaign_time{
        display: flex;
        flex-direction: column;
        width: max-content;
        margin: 10px 0;
        span{
            font-size: 12px;
        }
    }
    .campaign_notifications+.campaign_notifications{
        margin-top: 20px;
    }
    .campaign_notifications{
    
        .ant-input-affix-wrapper + .ant-input-affix-wrapper{
            margin-top: 15px;
        }
        .campaign_button{
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: ease-out 0.3s;
            border-radius: 3px;
            font-size: 18px;
        }
        .option_button_wrap{
            display: flex;
            justify-content: flex-end;
            padding: 10px 12px;
            &--button{
                background: #3b7ad5;
                color: white;
               
                &:hover{
                    
                    background: #275aa1;
                }
            }
        }
        position: relative;
        border: 1px solid #ddd;
        padding: 20px;
        .field_add_button{
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            margin: 5px 0 5px auto;
            width: max-content;
            border-radius: 5px;
            transition: ease-out 0.3s;
            font-size: 12px;
            &:hover{
                background: #eee;
            }
            svg{
                font-size: 22px;
                margin-right: 10px;
            }
        }
        &--title{
            position: absolute;
            top: -12px;
            background: white;
            padding: 2px 5px;
        }
        .ant-form-item{
            margin-bottom: 10px;
            .ant-form-item-label{
                padding: 0;
            }
        }
    }
}

.campaign_modal_title{
    display: flex;
    div{
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 10px;
    }
}
.grey{
    &:hover{
        background: #ddd;
    }
}