.contentBox {
  width: 280px;
  margin: 10px 20px;

  &--image {
    border: 0.5px solid #d8d8d8;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    height: 240px;
    &--edit {
      top: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      animation: initOverlay 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      svg {
        font-size: 40px;
        opacity: 0.8;
      }
    }
    img {
      width: auto;
      height: 100%;
    }
  }
  &--info {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    span {
      font-size: 13px;
    }
  }
}

@keyframes initOverlay {
  0% {
    opacity: 0;
    border-radius: 50%;
  }
  100% {
    opacity: 1;
    border-radius: 0%;
  }
}
