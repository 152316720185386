@import "./styles/header.scss";
@import "./styles/contacts.scss";
@import "./styles/dashboard.scss";
@import "./styles/marketing.scss";
@import "./styles/sidebar.scss";
@import "./styles/infoBox.scss";
@import "./styles/contentBox.scss";
@import "./styles/myContent.scss";
@import "./styles/marketplace.scss";
@import "./styles/marketPhone.scss";
@import "./styles/login.scss";
@import "./styles/loader.scss";
@import "./styles/myPage.scss";

// >>>>>> marketing page styles >>>>>>>>>>>>>

@import "./styles/marketing/cardBox.scss";
@import "./styles/marketing/campaignModal.scss";

// >>>>>>>>>>>varaibles >>>>>>>>>
$blue : #3b7ad5;

.App {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  min-height: 100vh;
}
.divider {
  text-transform: uppercase;
  font-size: 16px;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  &::after {
    margin-left: 10px;
    content: "";
    height: 2px;
    width: calc(100% - 10px);
    background: #f3f3f3;
  }
}
.page {
  padding: 40px 0 0 60px;
}

.cards-wrap {
  display: flex;
  padding: 20px;
  &--container {
    display: flex;
  }
  &--menu {
    margin-right: 20px;
    
    .active {
      text-decoration: underline;
    }
    div {
      font-size: 14px;
      cursor: pointer;
      font-weight: 500;
      text-transform: uppercase;
      padding: 0 10px;
    }
    div + div {
      margin-top: 10px;
      padding-top: 5px;
    }
  }
}

.ant-modal-content{
  border-radius: 5px;
  overflow: hidden;
}
.ant-modal-header{
  background-color: $blue !important;
 
  .ant-modal-title{
    font-size: 18px;
    color: white !important;
  }
}
.ant-tooltip-inner{
  font-size: 12px;
}
.infinite-scroll-component{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
}