$highlight : #3568c9;

.header{
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
    border-bottom: 2px solid #f3f3f3;
    &--items{
        .active{
            color: $highlight;
            position: relative;
        }
        .active::before{
            content: "";
            position: absolute;
            height: 2px;
            width: 100%;
            background: $highlight;
            bottom: -15px;
        }
        a{
            text-decoration: none;
            color: black;
            margin:  0 20px;
            text-transform: uppercase;
        }
    }
    .logo-container{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 40px;
        }
        div{
            margin-left: 20px;
            font-weight: 600;
            font-size: 22px;
        }
    }
}
.marketing-menu{
    div{
        color: #4d4e50;
        padding: 5px 10px;
        transition: ease-out 0.2s;
        &:hover{
            background: #f3f3f3;
        }
    }
    .active{
        color: $highlight;
    }
}
.burger{
    div{
        height: 5px;
        width: 36px;
        background: #4d4e50;
        border-radius: 10px;
        transition: cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
    }
    div + div{
        margin-top: 5px;
    }
    div:nth-child(1){
        transition-delay: 0.1s;
    }
    div:nth-child(2){
        transition-delay: 0.2s;
    }
    div:nth-child(3){
        transition-delay: 0.3s;
    }
}
.burger-active{
    div{
        transform: translateX(50px);
        opacity: 0;
    }
}
.menu_overlay{
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(2px);
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    a{
        color: #4d4e50;
        font-size: 14px;
    }
    a + a{
        margin-top: 6px;
    }
}
@media(min-width: 769px){
    .header{
        align-items: flex-end;
        .burger{
            display: none;
        }
    }
}

@media(max-width: 768px){
    .header{
        align-items: center;
        &--items{
            display: none;
        }
    }
}