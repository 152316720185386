.cards-wrap{
 
    &--controls{
        display: flex;
        align-items: center;
        &--counters{
            margin: 0 5px;
            font-size: 15px;
        }
        &--layout, &--cog{
            border-radius: 50%;
            min-width: 30px;
            height: 30px;
            margin-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 18px;
        }
       &--cog{
           color: #4dbfa9;
           font-size: 26px;
       }
        .active_layout{
            background: #4dbfa9;
            color: white;
        }
    }
}

.card_sorts{
    &--active{
        background: rgb(212, 212, 212);
    }
    &--non{
        &:hover{
            background: rgb(233, 233, 233);
        }
    }
    div{
        padding: 0 10px;
        cursor: pointer;
        transition: ease-out 0.3s;
       
    }
    div+ div{
        padding-top: 5px;
    }
}