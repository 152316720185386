.myContent-wrap {
  display: grid !important;
  grid-template-columns: auto auto auto;
}

.addContent {
  &--title {
    background: $highlight;
    color: white;
    padding: 20px;
  }
  &--uploader {
    position: relative;
    padding: 20px;
    width: max-content;
    &--camera {
      background: $highlight;
      color: white;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    input {
      position: absolute;
      height: 100%;
      top: 0;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
  .types {
    font-size: 12px;
    padding: 10px 20px;
    color: #adadad;
  }
}
.upload {
  cursor: pointer;
  border-radius: 5px;
  transition: ease-out 0.2s;
  &:hover {
    background: rgba(216, 216, 216, 0.5);
  }
}
