.infoBox{
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: white;
    width: 180px;
    &+ &{
        margin-left: 20px;
    }
    &--item{
        display: flex;
        flex-direction: column;
        span:nth-child(1){
            font-size: 26px;
        }
        span:nth-child(2){
            font-size: 14px;
        }
        &+ &{
            margin-top: 10px;
        }
    }
}