.login_container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    .ant-form{
        padding: 20px 40px;
        border-radius: 10px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    }
    &--buttons{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        button + button{
            margin-top: 10px;
        }
    }
}