.loader{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0 , 0 ,0 , 0.1);
    backdrop-filter: blur(2px);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        fill: rgb(58, 126, 216);
        font-size: 40px;
        animation: rotate 1.5s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
}

@keyframes rotate{
    from{
        transform: rotate(0);
    }
    to{
        transform: rotate(359deg);
    }
}