.marketplace-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  .total-selected{
    position: absolute;
    top: 10px;
    right: 10px;
    color: #3568c9;
    font-size: 13px;
  }
  
  &--orentation {
    display: flex;
    svg {
      font-size: 25px;
    }
    div {
      cursor: pointer;
    }
    div + div {
      margin-left: 10px;
    }
    .active {
      color: $highlight;
    }
    .disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  &--wrapper{
    display: flex;
    flex-wrap: wrap;
  }
}
.selected-menu{
  position: relative;
}
.selected-menu::before{
  content: "";
  height: 1px;
  width: calc(100% - 20px);
  position: absolute;
  background: black;
  bottom: -1px;
  animation: init 0.7s cubic-bezier(0.23, 1, 0.320, 1);
  transform-origin: center;
}
@keyframes init{
  from{
    left: 50%;
    width: 0;
  }
  to{
    left: 10px;
    width: calc(100% - 20px);
  }
}

.market-menu-main{

    margin-top: 20px;
  
}